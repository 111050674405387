import { Card, Button, Form, Row, Col, Image } from "react-bootstrap";
import { CartContext } from "../CartContex";
import { useContext } from "react";
import { Link } from 'react-router-dom';
import { getProductPrice } from "../productsStore"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'

// import { faTrophyStar } from '@fortawesome/free-regular-svg-icons'


function ProductCard(props){
  const product = props.product;
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id)

  const defaultVariations = product.variations
    ? Object.fromEntries(product.variations.map((v) => [v.key, v.options[0].value]))
    : {};

  const price = "price" in product ? product.price : getProductPrice(product.id, defaultVariations);
  const priceWdiscount =  ("price" in product && "discount" in product) ? ((100-product.discount)/100 * product.price) : getProductPrice(product.id, defaultVariations, true);

  return (
    <Card className="position-relative shadow-sm border border-2">
      {
        product.bestseller &&
          <span className="position-absolute top-0 badge rounded-pill bg-warning text-uppercase p-2 ps-3 pe-3 m-2">
            Bestseller <FontAwesomeIcon icon={faTrophy} />
          </span>
      }

      {
        product.coming_soon &&
          <span className="position-absolute top-0 badge rounded-pill bg-secondary text-uppercase p-2 ps-3 pe-3 m-2">
            Coming soon <FontAwesomeIcon icon={faHourglassHalf} />
          </span>
      }

      <Image key={"image-" + product.id}
             src={product.heroImage.src}
             alt={product.heroImage.alt}
             className="card-img-top"
             style={{ width: "100%", objectFit: "scale-down"}}
       />
      <Card.Body>
        <Card.Title className={ 'disabled' in product && "text-muted" }>
          {
            product.disabled == true
            ? <span className="text-muted">{product.title}</span>
            : <Link className="stretched-link text-underline-hover disabled" to={`/items/${encodeURIComponent(product.id)}`}>{product.title}</Link>

          }
        </Card.Title>
        <Card.Text className={ 'disabled' in product && "text-muted" }>
          { 'discount' in product == true
            ? <>
                $
                  {priceWdiscount.toFixed(2)}
                  <span class="badge rounded-pill bg-danger ms-1 fs-8">{product.discount}% off</span>
                  <span className="d-block ms-2 text-secondary">
                    <span className="text-decoration-line-through">
                      ${price.toFixed(2)}
                    </span>
                </span>
              </>
            : <><sup>$</sup>{price.toFixed(2)}</>
          }


          {/* {'price' in product ?
            <>
              <sup>$</sup>{product.price}
            </>
            :
            <>
              <sup>$</sup>{getProductPrices(product.id)[0]} – <sup>$</sup>{getProductPrices(product.id).pop()}
            </>
          } */}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}


export default ProductCard;