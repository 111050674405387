import { Button, Container, Navbar, Modal, Nav, Badge } from 'react-bootstrap'
import { useState, useContext } from 'react';
import { CartContext } from '../CartContex';
import CartProduct from './CartProduct';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'



function NavbarComponent() {
  const cart = useContext(CartContext);

  const handleClose = () => cart.showCart(false);
  const handleShow = () => {
    cart.showCart(true)
  }

  const [isCheckingOut, setCheckingOut] = useState(false);

  const checkout = async () => {
    setCheckingOut(true);

    await fetch("https://v3j9x2wohd.execute-api.us-east-1.amazonaws.com/api/checkout",{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ items: cart.items })
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if(response.url){
        window.location.assign(response.url);
      }
    })
  }
  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0)

  return (
    <>
      <Container className="mb-4">
        <Navbar expand="sm">
          <Navbar.Brand href="/">
            <img src="/icon.svg"></img> Print My Tools
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link key="products-link" href="/">Products</Nav.Link>
              <Nav.Link key="contacts-link" href="/contacts">Contacts</Nav.Link>
            </Nav>
            <Nav>
              <Button onClick={handleShow} className="position-relative">
                Cart <FontAwesomeIcon icon={faCartShopping} />
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                {productsCount}
                  <span className="visually-hidden">cart items</span>
                </span>
              </Button>
            </Nav>

          </Navbar.Collapse>
        </Navbar>
      </Container>

      <Modal show={cart.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Shopping Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsCount > 0 ?
            <>
              {cart.items.map((currentProduct, idx) =>(
                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity} variation={currentProduct.variation}></CartProduct>
              ))}
              <h5>Subtotal: ${cart.getTotalCost().toFixed(2)}</h5>
              <h5>
                Shipping:
                <span className="m-2 text-secondary" style={{color: 'blue !important'}}>Calculated at checkout</span>
              </h5>

              {/* <Button variant="success" onClick={checkout}>
                Checkout
              </Button> */}

              {isCheckingOut ? (
                // Render the processing button while the checkout process is ongoing
                <Button variant="success" disabled>
                  Processing
                  <span className="dot-animation dot1">.</span>
                  <span className="dot-animation dot2">.</span>
                  <span className="dot-animation dot3">.</span>
                </Button>
              ) : (
                // Render the regular "Checkout" button when the checkout process is not ongoing
                <Button variant="success" onClick={checkout}>
                  Checkout
                </Button>
              )}
            </>
            :
            <h3 style={{textAlign: 'center'}}>There are no items in your cart</h3>
          }
        </Modal.Body>
      </Modal>
    </>
  )

}

export default NavbarComponent;