import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import NavbarComponent from './components/navbar';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import Contacts from './pages/Contacts';
import Success from './pages/Success';
import CartProvider from './CartContex';
import ProductShow from './components/ProductShow';
import Footer from './components/footer';
import ReactGA from "react-ga4";

ReactGA.initialize("G-8RE5DMRVP3");

function App() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  return (
    <CartProvider>
      <Container className="d-flex flex-column min-vh-100">
        <NavbarComponent></NavbarComponent>
        <BrowserRouter>
          <Routes>
            <Route index element={<Store />} />
            <Route path="success" element={<Success />} />
            <Route path="cancel" element={<Cancel />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="/items/:id" element={<ProductShow />} />
          </Routes>
        </BrowserRouter>
        <Footer></Footer>
      </Container>
    </CartProvider>
  );
}

export default App;
