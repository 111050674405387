import { createContext, useState, useEffect } from "react";
import { productsArray, getProductData, getProductPrice } from "./productsStore";

export const CartContext = createContext({
  items: [],
  getProductQuantity: () => {},
  addOneToCart: () => {},
  removeOneFromCart: () => {},
  deleteFromCart: () => {},
  getTotalCost: () => {},
  showCart: () => {},
  show: true
})

export function CartProvider({children}){
  const [cartProducts, setCartProducts] = useState(() => {
    const saved = localStorage.getItem("cartProducts");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });

  useEffect(() => {
    localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
  }, [cartProducts]);

  const [show, setShow] = useState(false);

  const showCart = (value) => {
    setShow(value);
  };

  function getProductQuantity(id, variation){
    const quantity = cartProducts.find(product => product.id === id && product.variation === variation )?.quantity

    if (quantity == undefined){
      return 0;
    }

    return quantity;
  }

  function addOneToCart(id, variation){
    const quantity = getProductQuantity(id, variation);

    if (quantity == 0){
      setCartProducts(
        [
          ...cartProducts,
          {
            id: id,
            quantity: 1,
            variation: variation
          }
        ]
      )
    } else {
      setCartProducts(
        cartProducts.map(
          product =>
          (product.id === id && product.variation === variation)
          ? { ...product, quantity: product.quantity + 1, variation: variation}
          : product
        )
      )
    }
  }

  function deleteFromCart(id, variation){
    setCartProducts(
      cartProducts =>
      cartProducts.filter(currentProduct =>{
        return !(currentProduct.id === id && currentProduct.variation === variation)
      })
    )
  }

  function removeOneFromCart(id, variation){
    const quantity = getProductQuantity(id, variation);
    if (quantity == 1){
      deleteFromCart(1)
    } else {
      setCartProducts(
        cartProducts.map(
          product =>
          (product.id === id && product.variation === variation)
          ? { ...product, quantity: product.quantity - 1}
          : product
        )
      )
    }
  }

  function getTotalCost(){
    let totalCost = 0;

    cartProducts.map((cartItem) => {
      const productPrice = getProductPrice(cartItem.id, cartItem.variation, true)
      totalCost += (productPrice * cartItem.quantity);
    })

    return totalCost;
  }

  const contextValue = {
    items: cartProducts,
    getProductQuantity,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getTotalCost,
    show: show,
    showCart
  }


  return (
    <CartContext.Provider value={contextValue} >
      {children}
    </CartContext.Provider>
  )
}


export default CartProvider;