import { Button, Badge, ButtonGroup  } from 'react-bootstrap'
import { CartContext } from '../CartContex';
import { useContext, useState } from 'react';
import { getProductData, getProductPrice } from '../productsStore';



function CartProduct(props){
  const cart = useContext(CartContext);
  const id = props.id;
  const quantity = props.quantity;
  // const productData = getProductData(id);
  const product = getProductData(id);
  const variation = props.variation;

  const defaultVariations = product.variations
    ? Object.fromEntries(product.variations.map((v) => [v.key, v.options[0].value]))
    : {};

  const [price, setPrice] = useState("price" in product ? product.price : getProductPrice(product.id, defaultVariations));
  const [priceWdiscount, setPriceWdiscount] = useState("price" in product ? product.price : getProductPrice(product.id, defaultVariations, true));

  const hasDiscount = 'discount' in product

  // debugger

  let key = id + JSON.stringify(variation)

  return (
    <>
      <h5 style={{display: "inline-block"}} className="float-end">
         ${priceWdiscount.toFixed(2)}
            { hasDiscount &&
               <span className="ms-2 text-secondary">
                <span className="text-decoration-line-through fs-6">
                  <span class="badge rounded-pill bg-danger fs-8">
                    {product.discount}% off
                  </span>
                  <span class="ms-2">
                    ${price.toFixed(2)}
                  </span>
                </span>
              </span>
            }

      </h5>

      <h5>{product.title}</h5>

      <>
        {Object.entries(variation).map(([key, value]) => (
          <div style={{textTransform: 'capitalize'}} key={key}><strong>{key}: </strong>{value}</div>
        ))}
      </>
      <div>
        <div className="me-2" style={{display: "inline-block"}}>
          <strong>Quantity: </strong>{quantity}
        </div>

        <ButtonGroup size="sm" className="ml-4">
          <Button key="add" className="btn-sm bg-primary border-0 bg-gradient" onClick={() => cart.addOneToCart(product.id, variation)}>+</Button>
          <Button key="remove" className="btn-sm bg-secondary bg-gradient border-0" onClick={() => cart.removeOneFromCart(product.id, variation)}>-</Button>
        </ButtonGroup>

        <Button variant="link" className="link-secondary" onClick={() => cart.deleteFromCart(id, variation)}>Remove</Button>
      </div>

      <hr className="m-3" style={{width: '90%', margin: '0 auto'}}></hr>
    </>
  )
}

export default CartProduct;
