import React from 'react';
import { Form } from 'react-bootstrap';

const SelectBox = ({ options, value, onChange, label }) => {
  if (options.length === 1) {
    // Render a disabled input field with the value of the single option
    return (
      <Form.Group className="mb-2">
        <Form.Label><strong>{label}:</strong></Form.Label>
        <Form.Control type="text" value={options[0].label} disabled />
      </Form.Group>
    );
  }

  return (
    <Form.Group className="mb-2">
      <Form.Label><strong>{label}:</strong></Form.Label>
      <Form.Control as="select" value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default SelectBox;