import { Row, Col, Container } from 'react-bootstrap';
import { productsArray } from '../productsStore';

import ProductCard from '../components/ProductCard';

function Store(){
  return(
		<Container className="mb-4">
			<Row xs={1} md={3} className="g-4">
				{productsArray.map((product, idx) => (
					<Col align="center" key={idx}>
						<ProductCard product={product} />
					</Col>
				))}
			</Row>
		</Container>
  )
}
export default Store;