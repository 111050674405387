import React, { useState } from 'react';
import { Container, Row, Col, Image, Carousel, CarouselItem, Modal  } from 'react-bootstrap';

const SliderImageGallery = ({ images }) => {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleActiveImageClick = () => {
    setShowModal(true);
  };

  return (
    <Container className="mb-4">
      <Row>
        <Col sm={2} className="d-none d-sm-block">
          {images.map((image, i) => (
              <Image
                key={"image-" + i}
                src={image.src}
                alt={image.alt}
                className='border border-2 rounded'
                fluid
                onClick={() => setActiveImageIndex(i)}
                style={{ cursor: 'pointer', marginBottom: '10px' }}
              />
          ))}
        </Col>
        <Col sm={10}>
          <Carousel activeIndex={activeImageIndex} onSelect={setActiveImageIndex} interval={null}>
            {images.map((image, i) => (
              <CarouselItem key={i}>
                <Image src={image.src}
                       alt={image.alt}
                       onClick={handleActiveImageClick}
                       fluid className='border border-2 rounded'
                       style={{ cursor: 'zoom-in'}} />
              </CarouselItem>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Image
            src={images[activeImageIndex].src}
            alt={images[activeImageIndex].alt}
            fluid
            className='border border-2 rounded'
            onClick={() => setShowModal(false)}
            style={{ cursor: 'zoom-out'}}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SliderImageGallery;