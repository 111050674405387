import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';

function Contacts(){

	const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
		e.preventDefault();

		await fetch("https://v3j9x2wohd.execute-api.us-east-1.amazonaws.com/api/contact",{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if(response.status == "OK"){
				setFormData({});
      }
    })
  }

  return(
		<Container className="mb-4">
			<Row>
				<Col>
					<h1>Contacts</h1>
					<p>
						Do you have any questions about a current order or any general inquiries? Fill out the form below, and we will respond to you promptly!
					</p>

					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="formBasicName">
							<Form.Label>Name</Form.Label>
							<Form.Control type="text" name="name" value={formData.name || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" name="email" value={formData.email || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>Message</Form.Label>
							<Form.Control as="textarea" rows={4} name="message" value={formData.message || ''} onChange={handleChange} />
						</Form.Group>

						<Button variant="primary" type="submit">
							Send
						</Button>
					</Form>

				</Col>
			</Row>
		</Container>
  )
}
export default Contacts;