import { Nav } from 'react-bootstrap';
import React from 'react';

function Footer() {
  return (
    <footer className="footer bg-secondary bg-gradient text-light mt-auto rounded-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 ms-2 mt-4">
            <h5>Print My Tools</h5>
            <p>
              Building a better DIY world, one print at a time.
            </p>
          </div>
          <div className="col-lg-4 ms-2 mt-4">
            <h5>Links</h5>

            <Nav className="flex-column">
              <Nav.Link className="link-light" href="/">Products</Nav.Link>
              <Nav.Link className="link-light" href="https://www.etsy.com/ca/shop/printmytools">Etsy Store</Nav.Link>
              <Nav.Link className="link-light" href="/contacts">Contacts</Nav.Link>
            </Nav>

          </div>

        </div>
      </div>
      <div className="text-center py-3">
        &copy; {new Date().getFullYear()} Print My Tools. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;